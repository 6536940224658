<template>
<div>
    <div class="text-attribute"
        v-bind:class="[{'color_text': group.class=='color'},{'size_text':group.class=='tag'}]">
        {{group.name}}:
    </div>

    <div v-if="group.class=='color'" class="list-item list-color">
        <a v-for="att in group.attributes" :key="att.id" @click="toggle(att.id)" :style="getHexa(att.data)"
            v-bind:class="[{'active': isSelected(att.id)}]" class="color-box"></a>
    </div>

    <div v-if="group.class=='tag'" class="list-item list-size">
        <a v-for="att in group.attributes" :key="att.id"
            v-bind:class="[{'active': isSelected(att.id)}]"
            @click="toggle(att.id)">{{att.name}}</a>
    </div>

    <div v-if="group.class=='list'" class="list-item filter-choice">
        <select class="c-select" v-model="selected">
            <option :value="0">Ninguna</option>
            <option v-for="opt in group.attributes" :key="opt.id" :value="opt.id">
                {{opt.name}}
            </option>
        </select>
    </div>
</div>
</template>
<script>
export default {
  props: ["group"],
  data() {
    return {
      selected:0,
      preval:0,
    };
  },
  methods: {
    toggle(id) {
      if(this.selected==id){
        this.selected = 0;
      }else{
        this.selected=id;
      }
    },
    isSelected(id){
      return this.selected == id;
    },
    getHexa(field){
        let data = JSON.parse(field);
        let style = 'background-color: ' + data.code + ';border: 1px solid #eeeeee;';
        return style;
    },
  },
  watch: {
      'selected': {
          handler: function () {
              if(this.preval!=0){
                  this.$emit('update', this.preval);
              }
              this.preval = this.selected;
              this.$emit('update', this.selected);
            }
      },
    },
};
</script>
<style scoped>
.color-box {
  border: 1px solid;
  border-radius: 5px;
}
</style>
