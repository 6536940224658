<template>
  <div class="details-page">
    <div class="main-content main-content-details single no-sidebar">
      <div class="container">
        <Product />
      </div>
    </div>
  </div>
</template>

<script>
import Product from "../components/Product.vue";

export default {
  components: {
    Product,
  },
};
</script>

<style></style>
