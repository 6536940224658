<template>
  <div>
    <div class="variations">
      <div class="attribute" v-for="group in product.attList" :key="group.id"
        v-bind:class="[{'attribute_color': group.class=='color'},{'attribute_size':group.class=='tag'}]">

        <Picker :group="group" @update="updateAttributes"/>

      </div><br>
    </div>
    <div class="group-button">

        <div class="size-chart-wrapp" v-if="configuration.variant_check && variantExists==true">
            <div class="btn-size-chart">
                <a class="checker" @click.prevent="checkAvailability()">Comprobar disponibilidad</a>
            </div>
        </div>

      <div class="quantity-add-to-cart">
        <div class="quantity">
          <div class="control">
            <a class="btn-number" @click.prevent="updateProductAmount(selectedItem.amount-1)">
                -
            </a>
            <input
              type="number"
              v-model.number="itemAmount"
              title="Cantidad"
              class="input-qty qty"
              min="0"
              size="4"
              @input="updateValue"
            />
            <a  class="btn-number" @click.prevent="updateProductAmount(selectedItem.amount+1)">
                +
            </a>
          </div>
        </div>

        <button
          type="button"
          :disabled="!productReady()"
          class="single_add_to_cart_button button"
          v-bind:class="{'disabledInput': !productReady()}"
          @click="addToBasket()"
        >
          Agregar al carrito
        </button>
      </div>

      <transition name="fade">
        <div v-if="showSuccess" class="alert alert-success alert-container">
            <a class="close" @click.prevent="showSuccess = false">&times;</a>
            <strong>{{successMessage}}</strong>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="showError" class="alert alert-danger alert-container">
            <a class="close" @click.prevent="showError = false">&times;</a>
            <strong>{{errorMessage}}</strong>
        </div>
      </transition>

      <transition name="fade">
        <div class="variations">
            <div  class="attribute" v-if="avbVariants.length>0" >
                <Varlist :variants="avbVariants" @update="setVariant"/>
            </div>
        </div>
      </transition>

    </div>

    <!-- Modals -->
    <div
      class="modal fade"
      id="orderModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-body text-center">
            <div class="alert alert-success text-center" role="alert">
              El producto se agrego al carrito
            </div>
            <button type="button" class="btn btn-default" data-dismiss="modal">
              <router-link class="modal-router-link" :to="{ name: 'Store'}">
                Cerrar
              </router-link>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import Picker from "./AttributePicker.vue";
import Varlist from "./VariantList.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
    components: {
    Varlist,
    Picker
  },
  data() {
    return {
      showVariants:false,
      showError: false,
      showSuccess:false,
      errorMessage: '',
      successMessage:'',
      filterAtts: [],
      avbVariants:[],
      selectedItem: {
        key:0,
        product: {},
        amount: 1,
        variant: {},
        pricelist:0
      },
    };
  },
  computed: {
    ...mapGetters({
      configuration: "ecommerce/configuration",
      product: "ecommerce/product"
    }),
    itemAmount: {
      get: function () {
        return this.selectedItem.amount;
      },
      set: function (value) {
        if(!Number.isNaN(value) && value>0){
            if(this.configuration.sale_without_stock){
                this.selectedItem.amount = value;
            }else{
                if(Object.entries(this.selectedItem.variant).length===0){
                    if(value<=this.selectedItem.product.stock){
                        this.selectedItem.amount = value;
                    }else{
                        this.sendError('Stock insuficiente');
                    }
                }else{
                    if(value<=this.selectedItem.variant.stock){
                        this.selectedItem.amount = value;
                    }else{
                        this.sendError('Stock insuficiente');
                    }
                }
            }
        }
      }
    },
    variantExists:{
        get: function () {
            if(this.product.variants !== undefined){
                if(this.product.variants.length>0){
                    return true;
                }
            }
            return false;
        },
    },
  },

  watch: {
    product(newproduct){
      if(newproduct !== undefined && newproduct.id !== undefined){
          let item = JSON.parse(JSON.stringify(newproduct));
          delete item.attList;
          delete item.variants;
          item.attList=[];
          this.selectedItem.product = item;
      }
    },
    'filterAtts':{
        handler(){
            let arr =[];
            let vm= this;

            this.product.attList.forEach(group => {
                group.attributes.forEach(att =>{
                    if (vm.filterAtts.includes(att.id)){
                        arr.push(att);
                    }
                });
            });

            this.selectedItem.product.attList = JSON.parse(JSON.stringify(arr));
        }
    }
  },

  methods: {
    ...mapMutations({
      addToBasketMutator: 'ecommerce/ADD_TO_BASKET',
      setProduct: 'ecommerce/SET_PRODUCT'
    }),
    updateProductAmount(q) {
      this.selectedItem.amount = q;
    },
    setVariant(obj){
        if(!this.configuration.sale_without_stock && this.selectedItem.amount>obj.stock){
            this.sendError("Stock de variante insuficiente")
            this.avbVariants=[];
        }else{
            this.selectedItem.variant = obj;
        }
    },
    checkAvailability(){
        this.selectedItem.variant={};
        let vm=this;
        let available=[];
        if (this.product.variants.length>0 && this.filterAtts.length>0){
            vm.product.variants.forEach(element => {
                let atts = element.attributes.map(function(a) {return a.id;});
                let check = vm.filterAtts.every(i=>atts.includes(i));
                if(check){
                    this.sendSuccess('Variantes encontradas');
                    available.push(element);
                    vm.showVariants=true;
                }
            });
            if(available.length<1){
                this.avbVariants = [];
                this.sendError('No hay variantes con esas caracteristicas');
            }
        }
        this.avbVariants = JSON.parse(JSON.stringify(available));
    },

    addToBasket() {
      this.selectedItem.key = String(this.selectedItem.product.id);
      if(Object.entries(this.selectedItem.variant).length!==0){
          this.selectedItem.key = this.selectedItem.key + '-' + String(this.selectedItem.variant.id);
      }
      this.addToBasketMutator(this.selectedItem);
      this.sendSuccess("Producto agregado al carrito");
      this.clearProductDetail();
    },

    updateAttributes(id){
        if(id!==0){
            if(this.filterAtts.includes(id)){
                this.filterAtts.splice(this.filterAtts.findIndex(function(i){return i === id;}),1);
            }else{
                this.filterAtts.push(id);
            }
        }
    },
    productReady(){
        if(this.configuration.variant_check && this.variantExists==true>0){
            if(Object.entries(this.selectedItem.variant).length!==0){
                if(this.configuration.sale_without_stock){
                    return true;
                }else{
                    if(this.selectedItem.amount<=this.selectedItem.variant.stock){
                        return true;
                    }
                }
            }
        }else{
            if(this.configuration.sale_without_stock){
                return true;
            }else{
                if(this.selectedItem.amount<=this.product.stock){
                    return true;
                }
            }
        }

        return false;
    },
    sendError (message) {
        this.errorMessage = message;
        this.showError = true;

        let vm = this;
        setTimeout((function() {
          vm.showError = false;
        }),3000);
    },
    sendSuccess (message) {
        this.successMessage = message;
        this.showSuccess = true;

        let vm = this;
        setTimeout((function() {
          vm.showSuccess = false;
        }),3000);
    },
    clearProductDetail(){
        this.filterAtts=[];
        this.avbVariants=[];
        this.selectedItem.key=0;
        this.selectedItem.amount=1;
        this.selectedItem.variant={};
    },
    updateValue(event) {
      const value = event.target.value
      if (String(value).length <= 4) {
        this.selectedItem.amount = value
      } else {
        this.selectedItem.amount = parseInt(String(value).substring(0,4))
      }
    }
  },

  mounted(){
    if(this.product !== undefined && this.product.id !== undefined){
      let item = JSON.parse(JSON.stringify(this.product));
      delete item.attList;
      delete item.variants;
      this.selectedItem.product = item;
    }
  },
  beforeDestroy(){
    this.setProduct({});
  }
};
</script>

<style>

.disabledInput{
    pointer-events: none;
    background-color: lightgray;
}

.checker{
    cursor: pointer;
    font-weight: bold;
}

.alert-container {
  margin-top: 1em;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.single_add_to_cart_button:hover {
  background-color: var(--accent-color-dark);
}
.single_add_to_cart_button:active {
  background-color: var(--accent-color-light);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
