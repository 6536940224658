<template>
<div class="widget woof_Widget">
    <br>
    <div v-if="selected==0" class="text-attribute">
        <p>Variantes disponibles:</p>
    </div>
    <div v-else class="text-attribute">
        <p>Variante seleccionada:</p>
    </div>

    <div class="attribute-box">
        <a v-for="item in variants" :key="item.id"
            v-show="isActive(item.id)"
            v-bind:class="[{'active': isSelected(item.id)}]"
            @click="select(item)">
                <div v-for="att in item.attributes" :key="att.id">
                    {{att.name}}
                </div>
        </a>
    </div>

</div>

</template>
<script>
export default {
  props: ["variants"],
  data() {
    return {
      selected:0,
    };
  },
  methods: {
    isActive(id){
        if(this.selected==0 || this.selected==id)
            return true;
        return false;
    },
    isSelected(id){
      return this.selected == id;
    },
    select(item){
        if(this.selected==0){
            this.selected = item.id;
            this.$emit('update', item);
        }
    },
    getText(values){
      let list= '-  ';
      values.forEach(function(e,ix,array){
        list = list + e.name;
        if(ix===(array.length-1)){
            list = list + '  -';
        }else{
            list = list + ' / ';
        }
      });
      return list;
    }
  }
};
</script>
<style>




</style>
